
import { defineComponent, toRaw } from 'vue';
import NFTCard from '@/components/gem-bank/NFTCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

export default defineComponent({
  components: { NFTCard, Swiper, SwiperSlide },
  emits: ['selected', 'stake'],
  props: {
    title: String,
    nfts: Array,
    stake: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    const onSwiper = (swiper: any) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    const handleSelected = (e: any) => {
      ctx.emit('selected', e);
    };
    const handleStake = (nft: any) => {
      ctx.emit('stake', nft);
    };
    return {
      handleSelected,
      handleStake,
      onSwiper,
      onSlideChange,
    };
  },
});
