
import { defineComponent, ref, toRefs } from 'vue';
export default defineComponent({
  props: {
    nft: { type: Object, required: true },
    stake: { type: Boolean, required: true },
  },
  emits: ['selected', 'stake', 'unstake'],
  setup(props, ctx) {
    const { nft, stake } = toRefs(props); // make stake reactive
    const selected = ref<boolean>(false);

    const stakeNFT = () => {
      ctx.emit('stake', nft.value);
    };

    const unstakeNFT = () => {
      ctx.emit('stake', nft.value);
    };

    const toggleSelect = () => {
      selected.value = !selected.value;
      ctx.emit('selected', {
        nft: nft.value,
        selected: selected.value,
      });
    };

    return {
      selected,
      toggleSelect,
      stakeNFT,
      unstakeNFT,
      stake, // return stake
    };
  },
});
