<template>
  <div class="relative h-full min-h-screen">
    <router-view />
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
import TheFooter from '@/components/gem-farm/TheFooter';
import TheCat from '@/components/gem-farm/TheCat';
export default {
  components: { TheCat, TheFooter, TheNavBar },
};
</script>

<style>

input[type='radio']:checked + span {
  @apply text-black;
}
</style>
