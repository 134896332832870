import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ce923da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-nowrap relative overflow-x-auto overflow-y-hidden scrollbar-hide mt-[80px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFTCard = _resolveComponent("NFTCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft) => {
      return (_openBlock(), _createBlock(_component_NFTCard, {
        key: nft,
        nft: nft,
        stake: _ctx.stake,
        onStake: _ctx.handleStake,
        onSelected: _ctx.handleSelected
      }, null, 8, ["nft", "stake", "onStake", "onSelected"]))
    }), 128)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}